import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, Modal, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, Icon, warningIcon, closeIcon} from '@ace-de/ui-components/icons';
import {apsBudgetTypes} from '@ace-de/eua-entity-types';
import * as budgetActionTypes from '../budgetsActionTypes';
import ServicePerformanceTable from '../ui-elements/ServicePerformanceTable';
import AidServicesMaxTable from '../ui-elements/AidServicesMaxTable';
import MaxDurationsTable from '../ui-elements/MaxDurationsTable';

const formatBudgetData = (data, type) => {
    if (!data || !type) return;

    switch (type.toUpperCase()) {
        case apsBudgetTypes.SERVICE_PERFORMANCE: {
            return Object.keys(data).reduce((acc, tariff) => {
                const services = Object.keys(data[tariff]).map(service => data[tariff][service]);
                return [
                    ...acc,
                    services.map(service => Object.values(service).map(budgetData => ({
                        id: budgetData.id,
                        value: budgetData.value,
                    }))).flat(),
                ].flat();
            }, []);
        }
        case apsBudgetTypes.AID_SERVICE_MAX:
        case apsBudgetTypes.MAX_DURATIONS: {
            return Object.keys(data).reduce((formattedBudgetData, service) => {
                return [
                    ...formattedBudgetData,
                    ...(Object.values(data[service])
                        .map(budgetData => ({
                            id: budgetData.id,
                            value: budgetData.value,
                        }))),
                ];
            }, []);
        }
        default:
            return data;
    }
};

const checkIsBudgetDataValid = formData => {
    return Object.values(formData).reduce((isBudgetDataValid, budgetData) => {
        if (!budgetData) return isBudgetDataValid;

        if (typeof budgetData.value !== 'undefined') {
            return budgetData.value !== '' && budgetData.value >= 0 && isBudgetDataValid;
        }
        return checkIsBudgetDataValid(budgetData) && isBudgetDataValid;
    }, true);
};

const EditBudgetsModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_budgets_modal');
    const {location, hasBackdrop, declineUpdateBudgets, confirmUpdateBudgets} = props;
    const {servicePerformanceBudgets, aidServiceMaxBudgets, maxDurationBudgets} = props;
    const [formData, setFormData] = useState(null);
    const [isFormValid, setIsFormValid] = useState(true);
    const modalType = location?.query?.type;

    useEffect(() => {
        if (!modalType) return;

        switch (modalType.toUpperCase()) {
            case apsBudgetTypes.SERVICE_PERFORMANCE: {
                setFormData(servicePerformanceBudgets);
                break;
            }
            case apsBudgetTypes.AID_SERVICE_MAX: {
                setFormData(aidServiceMaxBudgets);
                break;
            }
            case apsBudgetTypes.MAX_DURATIONS: {
                setFormData(maxDurationBudgets);
                break;
            }
            default:
                break;
        }
    }, [modalType, servicePerformanceBudgets, aidServiceMaxBudgets, maxDurationBudgets]);

    useEffect(() => {
        if (!formData) return;
        setIsFormValid(checkIsBudgetDataValid(formData));
    }, [formData]);

    return (
        <Modal
            title={translateModal(`modal_title.${modalType}`)}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineUpdateBudgets}
                />
            )}
            hasBackdrop={hasBackdrop}
            className={cx('global!ace-u-modal-content-size--m')}
            contentClassName={cx('ace-c-modal__content--scrollable')}
        >
            <div className={cx('global!ace-u-margin--top-16')}>
                {modalType.toUpperCase() === apsBudgetTypes.SERVICE_PERFORMANCE && (
                    <ServicePerformanceTable isEdit={true} onChange={setFormData} formData={formData} />
                )}
                {modalType.toUpperCase() === apsBudgetTypes.AID_SERVICE_MAX && (
                    <AidServicesMaxTable isEdit={true} onChange={setFormData} formData={formData} />
                )}
                {modalType.toUpperCase() === apsBudgetTypes.MAX_DURATIONS && (
                    <MaxDurationsTable isEdit={true} onChange={setFormData} formData={formData} />
                )}
            </div>
            {!isFormValid && (
                <p
                    className={cx([
                        'global!ace-u-margin--top-16',
                        'global!ace-u-margin--bottom-16',
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {translateModal('error_message.field_empty')}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    name="confirmUpdateBudgets"
                    onClick={() => confirmUpdateBudgets({
                        budgets: formatBudgetData(formData, modalType),
                        budgetPeriodId: location?.query?.budgetPeriodId,
                    })}
                    isDisabled={!formData || !isFormValid}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

EditBudgetsModal.propTypes = {
    location: PropTypes.object.isRequired,
    declineUpdateBudgets: PropTypes.func.isRequired,
    confirmUpdateBudgets: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool.isRequired,
    servicePerformanceBudgets: PropTypes.object,
    aidServiceMaxBudgets: PropTypes.object,
    maxDurationBudgets: PropTypes.object,
};

EditBudgetsModal.defaultProps = {
    servicePerformanceBudgets: null,
    aidServiceMaxBudgets: null,
    maxDurationBudgets: null,
};

const mapStateToProps = state => ({
    servicePerformanceBudgets: state.budgets.servicePerformanceBudgets,
    aidServiceMaxBudgets: state.budgets.aidServiceMaxBudgets,
    maxDurationBudgets: state.budgets.maxDurationBudgets,
});

const mapDispatchToProps = dispatch => ({
    declineUpdateBudgets: () => dispatch({
        type: budgetActionTypes.DECLINE_UPDATE_BUDGETS,
    }),
    confirmUpdateBudgets: payload => dispatch({
        type: budgetActionTypes.CONFIRM_UPDATE_BUDGETS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBudgetsModal);
