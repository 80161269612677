import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {NumberInput, Table, TableBody, TableCell, TableHead, TableRow, useStyles} from '@ace-de/ui-components';
import {apsServiceTypes, apsMemberBudgetTariffTypes} from '@ace-de/eua-entity-types';
import budgetedMaxDurationServiceTypes from '../budgetedMaxDurationServiceTypes';

const MaxDurationsTable = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {isEdit, onChange, maxDurationBudgets, formData} = props;

    const handleOnChange = (service, tariffGroup, value) => {
        if (typeof onChange !== 'function') return;

        onChange(prevState => ({
            ...prevState,
            [service]: {
                ...(prevState[service]),
                [tariffGroup]: {
                    ...(prevState[service]?.[tariffGroup]),
                    value,
                },
            },
        }));
    };

    if (!maxDurationBudgets) return null;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={3}>
                        {translateScreen('table_header.service')}
                    </TableCell>
                    {Object.keys(apsMemberBudgetTariffTypes).map(tariffGroup => (
                        <TableCell
                            key={tariffGroup}
                            colSpan={tariffGroup === apsMemberBudgetTariffTypes.CLASSIC ? 2 : 1}
                        >
                            {translateScreen(`table_header.tariff_${tariffGroup.toLowerCase()}`)}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(apsServiceTypes)
                    // we're listing only service types that are budgeted on the BE,
                    // story https://computerrock.atlassian.net/browse/ACELEA-3109
                    .filter(serviceType => budgetedMaxDurationServiceTypes.includes(serviceType))
                    .map(service => (
                        <TableRow key={service}>
                            <TableCell colSpan={3}>
                                {translateScreen(`table_cell.service_${service.toLowerCase()}`)}
                            </TableCell>
                            {!isEdit
                                ? !!maxDurationBudgets[service]
                                    && Object.keys(apsMemberBudgetTariffTypes).map(tariffGroup => (
                                        <TableCell
                                            key={`${service}-${tariffGroup}`}
                                            colSpan={tariffGroup === apsMemberBudgetTariffTypes.CLASSIC ? 2 : 1}
                                        >
                                            {maxDurationBudgets[service][tariffGroup]?.value || '-'}
                                        </TableCell>
                                    ))
                                : Object.keys(apsMemberBudgetTariffTypes).map(tariffGroup => {
                                    return (
                                        <TableCell
                                            key={`${service}-${tariffGroup}`}
                                            colSpan={tariffGroup === apsMemberBudgetTariffTypes.CLASSIC ? 2 : 1}
                                        >
                                            <NumberInput
                                                name={`${service}-${tariffGroup}`}
                                                className={cx([
                                                    'global!ace-u-width--full',
                                                    'ace-c-number-input--small',
                                                ])}
                                                value={formData?.[service]?.[tariffGroup]?.value >= 0
                                                    ? formData?.[service]?.[tariffGroup]?.value
                                                    : ''}
                                                onChange={value => handleOnChange(service, tariffGroup, value)}
                                            />
                                        </TableCell>
                                    );
                                })}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

MaxDurationsTable.propTypes = {
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    maxDurationBudgets: PropTypes.object,
    formData: PropTypes.object,
};

MaxDurationsTable.defaultProps = {
    isEdit: false,
    onChange: null,
    maxDurationBudgets: null,
    formData: null,
};

const mapStateToProps = state => ({
    maxDurationBudgets: state.budgets.maxDurationBudgets,
});

export default connect(mapStateToProps)(MaxDurationsTable);
