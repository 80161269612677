import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {NumberInput, Table, TableBody, TableCell, TableHead, TableRow, ToggleTab, ToggleTabSwitch, useStyles} from '@ace-de/ui-components';
import {apsServiceTypes, apsMemberBudgetTariffTypes} from '@ace-de/eua-entity-types';
import memberStatuses from '../memberStatuses';
import budgetedServicePerformanceServiceTypes from '../budgetedServicePerformanceServiceTypes';

const ServicePerformanceTable = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {isEdit, onChange, servicePerformanceBudgets, formData} = props;
    const [selectedBudgetTariff, setSelectedBudgetTariff] = useState(apsMemberBudgetTariffTypes.CLASSIC);

    const handleOnChange = (tariff, service, memberStatus, value) => {
        if (typeof onChange !== 'function') return;

        onChange(prevState => ({
            ...prevState,
            [tariff]: {
                ...(prevState[tariff]),
                [service]: {
                    ...(prevState[tariff]?.[service]),
                    [memberStatus]: {
                        ...(prevState[tariff]?.[service]?.[memberStatus]),
                        value,
                    },
                },
            },
        }));
    };

    if (!servicePerformanceBudgets) return null;

    return (
        <div>
            <ToggleTabSwitch
                name="budgetTariff"
                className={cx('global!ace-u-margin--bottom-24')}
                onChange={value => setSelectedBudgetTariff(value)}
                value={selectedBudgetTariff}
            >
                {Object.values(apsMemberBudgetTariffTypes).map(tab => (
                    <ToggleTab
                        key={tab}
                        name={`${tab.toLowerCase()}Tab`}
                        value={tab}
                    >
                        {translateScreen(`budget_tariff.${tab.toLowerCase()}`)}
                    </ToggleTab>
                ))}
            </ToggleTabSwitch>
            <Table className={cx('global!ace-u-margin--top-24')}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {translateScreen('table_header.service')}
                        </TableCell>
                        {memberStatuses.map(status => (
                            <TableCell key={status}>
                                {translateScreen(`table_header.member_status_${status.toLowerCase()}`)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(apsServiceTypes)
                        // we're listing only service types that are budgeted on the BE,
                        // story https://computerrock.atlassian.net/browse/ACELEA-3077
                        .filter(serviceType => budgetedServicePerformanceServiceTypes.includes(serviceType))
                        .map(service => (
                            <TableRow key={service}>
                                <TableCell colSpan={2}>
                                    {translateScreen(`table_cell.service_${service.toLowerCase()}`)}
                                </TableCell>
                                {!isEdit
                                    ? !!servicePerformanceBudgets[selectedBudgetTariff]?.[service]
                                        && memberStatuses.map(memberStatus => (
                                            <TableCell key={`${service}-${memberStatus}`}>
                                                {servicePerformanceBudgets[
                                                    selectedBudgetTariff
                                                ][service][memberStatus]?.value}
                                            </TableCell>
                                        ))
                                    : memberStatuses.map(status => (
                                        <TableCell key={`${service}-${status}`}>
                                            <NumberInput
                                                name={`${service}-${status}`}
                                                className={cx([
                                                    'global!ace-u-width--full',
                                                    'ace-c-number-input--small',
                                                ])}
                                                value={formData?.[
                                                    selectedBudgetTariff
                                                ]?.[service]?.[status]?.value >= 0
                                                    ? formData?.[selectedBudgetTariff]?.[service]?.[status]?.value
                                                    : ''}
                                                onChange={value => {
                                                    handleOnChange(selectedBudgetTariff, service, status, value);
                                                }}
                                            />
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
};

ServicePerformanceTable.propTypes = {
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    servicePerformanceBudgets: PropTypes.object,
    formData: PropTypes.object,
};

ServicePerformanceTable.defaultProps = {
    isEdit: false,
    onChange: null,
    servicePerformanceBudgets: null,
    formData: null,
};

const mapStateToProps = state => ({
    servicePerformanceBudgets: state.budgets.servicePerformanceBudgets,
});

export default connect(mapStateToProps)(ServicePerformanceTable);
